import fetch from 'isomorphic-fetch'
import React, { useState } from 'react'
import Client from 'shopify-buy'
import Context from 'src/context/StoreContext'
import useQueryParam from 'src/hooks/useQueryParams'

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const discountCode = useQueryParam('discount', '')

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    if (discountCode[0] !== '') {
      client.checkout
        .addDiscount(checkout.id, discountCode[0])
        .then(checkout => {})
        .catch(e => console.error(e))
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID)
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }]

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then(res => {
      setCheckout(res)
      setLoading(false)
    })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then(res => {
      setCheckout(res)
      setLoading(false)
    })
  }

  const addVariantToCart = (variantId, quantity, customAttributes) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      },
    ]

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then(res => {
      setCheckout(res)
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000)
    })
  }

  const [showHeadwearLinks, setShowHeadwearLinks] = useState(false)
  const [showApparelLinks, setShowApparelLinks] = useState(false)
  const [showCollectionLinks, setShowCollectionLinks] = useState(false)

  return (
    <Context.Provider
      value={{
        ...defaultValues,
        didJustAddToCart,
        loading,
        checkout,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        showHeadwearLinks,
        toggleHeadwearLinks: () => setShowHeadwearLinks(!showHeadwearLinks),
        showApparelLinks,
        toggleApparelLinks: () => setShowApparelLinks(!showApparelLinks),
        showCollectionLinks,
        toggleCollectionLinks: () => setShowCollectionLinks(!showCollectionLinks),
        hideHeadwearLinks: () => setShowHeadwearLinks(false),
        hideApparelLinks: () => setShowApparelLinks(false),
        hideCollectionLinks: () => setShowCollectionLinks(false),
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default StoreProvider
